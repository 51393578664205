<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-form ref="form" :model="formData" label-width="120px">
            <el-form-item label="门店名称" prop="storeName" :rules="rules">
                <el-input
                    style="width: 400px"
                    v-model="formData.storeName"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="门店类型" prop="storeType" :rules="rules">
                <el-select
                    style="width: 400px"
                    v-model="formData.storeType"
                    placeholder="请选择"
                    size="small"
                    clearable
                    :disabled="storeTypeDisabled"
                >
                    <el-option
                        v-for="item in dict_store_type"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="门店标签" prop="storeTagList">
                <div class="store-tags">
                    <el-tag
                        :key="tag"
                        v-for="tag in formData.storeTagList"
                        :disable-transitions="false"
                        closable
                        @close="handleCloseTag(tag)"
                    >
                        {{ tag }}
                    </el-tag>
                    <el-input
                        class="input-new-tag"
                        v-if="tagInputVisible"
                        v-model="tagInputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="handleTagInputConfirm"
                        @blur="handleTagInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showTagInput"
                        >+ 新增标签</el-button
                    >
                </div>
            </el-form-item>
            <el-form-item label="负责人姓名" prop="legalName" :rules="rules">
                <el-input
                    style="width: 400px"
                    v-model="formData.legalName"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="负责人手机号" prop="legalMobile" :rules="rules">
                <el-input
                    style="width: 400px"
                    v-model="formData.legalMobile"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="开户行">
                <el-input
                    style="width: 400px"
                    v-model="formData.bankName"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号">
                <el-input
                    style="width: 400px"
                    v-model="formData.bankCardNo"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="licenseImage" :rules="rules">
                <FileUpload
                    :name.sync="formData.licenseImage"
                    :url="formData.licenseImageUrl"
                ></FileUpload>
            </el-form-item>
            <el-form-item label="门头照片" prop="facadeImageList" :rules="rules">
                <FileUpload
                    :isMulti="true"
                    :name.sync="formData.facadeImageList"
                    :url="formData.facadeImageUrlList"
                ></FileUpload>
            </el-form-item>
            <el-form-item label="保险金系数" prop="insuranceRate" :rules="rules">
                <el-input-number
                    style="width: 400px"
                    size="small"
                    v-model="formData.insuranceRate"
                    :precision="2"
                    :step="0.01"
                    :min="0"
                ></el-input-number>
                <span class="tip">注：保险金=零售价*保险金系数</span>
            </el-form-item>
            <el-form-item label="监管锁费" prop="lockAmount" :rules="rules">
                <el-input-number
                    style="width: 400px"
                    size="small"
                    v-model="formData.lockAmount"
                    :precision="2"
                    :step="0.01"
                    :min="0"
                ></el-input-number>
            </el-form-item>
            <el-form-item label="门店归属" prop="businessUserId" :rules="rules">
                <SelectAdmin v-model="formData.businessUserId"></SelectAdmin>
            </el-form-item>
            <el-form-item label="所在地区" prop="lat" :rules="rules">
                <el-cascader
                    disabled
                    style="margin-right: 20px; width: 300px"
                    v-model="positionV"
                    size="mini"
                    :options="options"
                ></el-cascader>
                <el-link @click="showMap" type="primary" :underline="false" icon="el-icon-location"
                    >选择位置</el-link
                >
            </el-form-item>
            <el-form-item label="详细地址" prop="addressDetail" :rules="rules">
                <el-input
                    style="width: 400px"
                    type="textarea"
                    v-model="formData.addressDetail"
                ></el-input>
            </el-form-item>
            <el-form-item label="" prop="settleType">
                <el-radio-group v-model="formData.settleType" @change="onSettleType">
                    <el-radio :label="1">线下打款</el-radio>
                    <el-radio :label="2">支付宝自动结算</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                label="姓名"
                prop="payeeIdName"
                :rules="rules"
                v-if="formData.settleType === 2"
            >
                <el-input
                    style="width: 400px"
                    v-model="formData.payeeIdName"
                    placeholder="请输入姓名"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="支付宝账号"
                prop="payeeAlipayAccount"
                :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]"
                v-if="formData.settleType === 2"
            >
                <el-input
                    style="width: 400px"
                    v-model="formData.payeeAlipayAccount"
                    placeholder="请输入支付宝账号"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label="详细地址" prop="addressDetail" :rules="rules">
        <el-input style="width:400px" v-model="formData.addressDetail" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="经纬度" prop="lng" :rules="rules">
        <el-input style="width:190px" v-model="formData.lng" placeholder="请输入" size="small">
          <template slot="prepend">经度</template>
        </el-input>
        <el-input style="width:190px;margin:0 20px" v-model="formData.lat" placeholder="请输入" size="small">
          <template slot="prepend">纬度</template>
        </el-input>
        <el-link @click="showMap" icon="el-icon-location" type="primary" :underline="false">选择位置</el-link>
      </el-form-item> -->

            <el-form-item label="">
                <el-button type="primary" size="small" @click="save">保存</el-button>
                <el-button type="" size="small" @click="$router.back()">取消</el-button>
            </el-form-item>
        </el-form>
        <!--  -->
        <el-dialog title="位置" :visible.sync="dialogVisible" width="width">
            <template>
                <GdMap
                    ref="map"
                    :lng.sync="formData.lng"
                    :lat.sync="formData.lat"
                    :province.sync="address.province"
                    :city.sync="address.city"
                    :district.sync="address.district"
                ></GdMap>
            </template>
            <div slot="footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false" size="small"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <div style="height: 50px"></div>
    </div>
</template>

<script>
import { getStoreDetailAPI, addStoreAPI, editStoreAPI } from './api';
import FileUpload from '@/components/file-upload/index.vue';
import SelectAdmin from '@/views/components/select-admin.vue';
// import GdMap from '@/components/GdMap/gd-map.vue'
import GdMap from './gd-map.vue';
import { dict_store_type } from '@/enum/dict.js';

export default {
    name: 'AddOrEdit',
    components: { FileUpload, SelectAdmin, GdMap },
    data() {
        return {
            dialogVisible: false,
            rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
            storeTypeDisabled: false,
            dict_store_type,
            formData: {
                storeName: '',
                storeTagList: [],
                legalName: '',
                legalMobile: '',
                licenseImage: '',
                facadeImageList: '',
                lng: '',
                lat: '',
                addressDetail: '',
                // priceRate: '35',
                insuranceRate: 0,
                lockAmount: 0,
                businessUserId: '',
                bankName: '',
                bankCardNo: '',
                payeeIdName: '',
                settleType: 2,
                payeeAlipayAccount: '',
            },
            positionV: ['province', 'city', 'district'],
            address: {
                province: '省',
                city: '市',
                district: '区',
            },
            tagInputVisible: false,
            tagInputValue: '',
        };
    },
    computed: {
        options() {
            const { province, city, district } = this.address;
            let arr = [];
            arr = [
                {
                    value: 'province',
                    label: province,
                    children: [
                        {
                            value: 'city',
                            label: city || province,
                            children: [
                                {
                                    value: 'district',
                                    label: district,
                                },
                            ],
                        },
                    ],
                },
            ];
            return arr;
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.getStoreDetail();
            this.storeTypeDisabled = true;
        }
    },
    methods: {
        onSettleType(val) {
            console.log(val);
        },
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        editStoreAPI(this.formData).then(() => {
                            this.$message.success('编辑成功');
                            this.$router.back();
                        });
                    } else {
                        addStoreAPI(this.formData).then(() => {
                            this.$message.success('新增成功');
                            this.$router.back();
                        });
                    }
                }
            });
        },
        async getStoreDetail() {
            const res = await getStoreDetailAPI(this.$route.params.id);
            this.formData = res;
            const { province, city, district } = res;
            this.address = {
                province: province || '',
                city: city || '',
                district: district || '',
            };
        },
        showMap() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.map.tipinput = '';
                setTimeout(() => {
                    this.$refs.map.initMap();
                    // this.$refs.map.getLocation()
                });
            });
        },
        handleCloseTag(tag) {
            this.formData.storeTagList.splice(this.formData.storeTagList.indexOf(tag), 1);
        },
        showTagInput() {
            this.tagInputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleTagInputConfirm() {
            if (this.formData.storeTagList.length >= 5) {
                this.$message.error('标签不能超过5个');
                return;
            }
            let inputValue = this.tagInputValue;
            if (inputValue) {
                this.formData.storeTagList.push(inputValue);
            }
            this.tagInputVisible = false;
            this.tagInputValue = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    .tip {
        margin-left: 20px;
        color: #f56c6c;
    }
    /* switch按钮样式 */
    .switch .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
    }
    /*打开时文字位置设置*/
    .switch .el-switch__label--right {
        z-index: 1;
    }
    /* 调整打开时文字的显示位子 */
    .switch .el-switch__label--right span {
        margin-left: 5px;
    }
    /*关闭时文字位置设置*/
    .switch .el-switch__label--left {
        z-index: 1;
    }
    /* 调整关闭时文字的显示位子 */
    .switch .el-switch__label--left span {
        margin-left: 20px;
    }
    /*显示文字*/
    .switch .el-switch__label.is-active {
        display: block;
    }
    /* 调整按钮的宽度 */
    .switch.el-switch .el-switch__core,
    .el-switch .el-switch__label {
        width: 55px !important;
        margin: 0;
    }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
    .el-form-item {
        .store-tags {
            width: 500px;
            .el-tag + .el-tag {
                margin-left: 10px;
            }
            .button-new-tag {
                margin-left: 10px;
                height: 32px;
                line-height: 30px;
                padding-top: 0;
                padding-bottom: 0;
            }
            .input-new-tag {
                width: 90px;
                margin-left: 10px;
                vertical-align: bottom;
            }
        }
    }
}
</style>
